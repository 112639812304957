<template>
  <div class="row">
    <div class="col-lg-4" v-for="(item, id) in data?.companies" :key="id">
      <div class="white-box">
        <div class="pt-3 ms-3">
          <h2 class="bold-19 text-gradient mb-2">{{ item.name }}</h2>
        </div>
        <div class="d-flex ms-3">
          <h3 class="bold-24 text-gradient mb-2 flex-1-1-0">
            {{ $t("finance.sales") }}
          </h3>
          <div class="bold-24 text-gradient">
            <EditOnClick
              :model-value="item.total"
              type="price"
              :editable="false"
            />
          </div>
        </div>
        <div class="row ms-3">
          <div class="col-6">
            <div class="d-flex">
              <h3 class="bold-16 text-green mb-2 flex-1-1-0">
                {{ $t("finance.income") }}
              </h3>
              <div class="bold-16 text-green">
                <EditOnClick
                  :model-value="item.income"
                  type="price"
                  :editable="false"
                />
              </div>
            </div>
            <div class="d-flex">
              <h3 class="bold-16 text-danger mb-2 flex-1-1-0">
                {{ $t("finance.outcome") }}
              </h3>
              <div class="bold-16 text-danger">
                <EditOnClick
                  :model-value="item.outcome"
                  type="price"
                  :editable="false"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex">
              <h3 class="bold-16 text-green mb-2 flex-1-1-0">
                {{ $t("finance.income") }}
              </h3>
              <div class="bold-16 text-green">
                <EditOnClick
                  :model-value="item.waiting_income"
                  type="price"
                  :editable="false"
                />
              </div>
            </div>
            <div class="d-flex">
              <h3 class="bold-16 text-danger mb-2 flex-1-1-0">
                {{ $t("finance.outcome") }}
              </h3>
              <div class="bold-16 text-danger">
                <EditOnClick
                  :model-value="item.waiting_outcome"
                  type="price"
                  :editable="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nature-white-box">
      <div class="white-box-top"></div>
      <div class="row">
        <TopBar
          v-model:selectAll="selectAll"
          v-model:line-options="lineOptions"
          v-model:rows="rows"
          v-model:items="items"
          :show-currencies="false"
          :show-languages="false"
          path="finance"
          :show-add="false"
          :show-change="false"
          :show-delete="false"
          :show-select="false"
        />
        <DynamicTable
          v-model:rows="rows"
          v-model:items="items"
          v-model:select-all="selectAll"
          type="finance"
          :fix-height="true"
          :show-select="false"
          :show-pin="false"
          :key="tableKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import date from "@/modules/date";
import { useStore } from "vuex";
import EditOnClick from "../../components/inputs/EditOnClick";
import DynamicTable from "../../components/lists/DynamicTable";
import TopBar from "../../components/lists/TopBar";
import lineOptions from "../../modules/lineOptions";

export default {
  name: "Finance",
  components: { TopBar, DynamicTable, EditOnClick },

  data() {
    return {
      store: useStore(),
      data: null,
      items: undefined,
      rows: [],
      lineOptions: [],
      selectAll: false,
      tableKey: 0,
      filtersTimeOut: null,
      firstLoad: true,
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    date() {
      return this.store.state.topBar.date;
    },
    filters() {
      return this.store.state.filters?.financeSales;
    },
    q() {
      return this.store.state.topBar.q;
    },
    currencies() {
      return this.store.state.currencies;
    },
  },
  mounted() {
    this.$store.commit("setFilterPage", "financeSales");
    this.loadData();
  },
  watch: {
    warehouse() {
      this.loadData();
    },
    filters: {
      deep: true,
      handler() {
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.items = undefined;
          this.page = 1;
          this.loadData();
        }, 300);
      },
    },
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.page = 1;
        this.loadData();
      }, 450);
    },
    date: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    formatDate(val) {
      return date.format(val, true, true);
    },
    loadData() {
      this.items = undefined;
      http
        .fetch(
          "/finance?" +
            (this.warehouse ? "&warehouse=" + this.warehouse : "") +
            (this.q ? "&q=" + this.q : "") +
            (this.filters?.currency
              ? "&currency=" + this.filters.currency.value[0]
              : "") +
            (this.date[0]
              ? "&from=" + this.date[0].toISOString().split("T")[0]
              : "") +
            (this.date[1]
              ? "&to=" + this.date[1].toISOString().split("T")[0]
              : "")
        )
        .then((data) => {
          this.rows = lineOptions.init(data.rows, data.line_options);
          this.lineOptions = data.line_options;
          this.selectedLineOption = data.line_options[0].id;
          this.data = data;
          this.items = data.data;
          this.tableKey++;

          if (this.firstLoad) {
            let filterOptions = [];
            filterOptions.push({
              name: "Currency",
              type: "string",
              key: "currency",
              value: {},
              values: this.currencies.map((e) => {
                return {
                  id: e.code,
                  value: e.code,
                };
              }),
            });

            this.$store.commit("setFilterPage", "finance");
            this.$store.commit("setFilterOptions", filterOptions);
            this.$store.commit("setFilterOptionsStock", false);
            this.$store.commit("setFilterOptionsPrice", false);
            this.firstLoad = false;
          }
        });
    },
  },
};
</script>
